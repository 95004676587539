import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import * as Global from "src/app/global";

@Component({
  selector: "app-audiogram-list",
  templateUrl: "./audiogram-list.component.html",
  styleUrls: ["./audiogram-list.component.css"],
})
export class AudiogramListComponent implements OnInit {
  Global = Global;
  public patientName: string;
  public audiograms: { [k: string]: any }[] = [];
  public patientId: string = "";

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.patientId = params["patientId"];
      this.apiService
        .get("/audiologist/audiogram-list/" + this.patientId)
        .subscribe((data) => {
          this.audiograms = data;
        });
    });

    this.apiService
      .get("/audiologist/patient/" + this.patientId)
      .subscribe((data) => {
        this.patientName = data.name;
      });
  }
}
