import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.css"],
})
export class AdminLoginComponent implements OnInit {
  public data: { [k: string]: any } = {};
  private doSubmit: boolean = true;

  constructor(private titleService: Title, private apiService: ApiService) {}

  ngOnInit(): void {
    this.titleService.setTitle("Admin Login");
  }
  submit() {
    if (this.doSubmit) {
      this.doSubmit = false;
      this.apiService.post("/admin/login", this.data).subscribe(
        (res) => {
          localStorage.setItem("token", res.token);
          window.location.href = "/admin";
        },
        (err) => {
          this.doSubmit = true;
          alert(err.error);
        }
      );
    }
  }
}
