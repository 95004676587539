import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SuggestionService } from "src/app/services/suggestion.service";

@Component({
  selector: "app-audiometric",
  templateUrl: "./audiometric.component.html",
  styleUrls: ["./audiometric.component.css"],
})
export class AudiometricComponent implements OnInit {
  public weberNote: string = "";
  public rinneNote: string = "";
  @Output() dataEmitter = new EventEmitter<string>();
  @Input() initData: any;
  @Input() weberSuggestions: string[] = [];
  @Input() rinneSuggestions: string[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.initData != "none") {
      this.weberNote = this.initData.weberNote;
      this.rinneNote = this.initData.rinneNote;
    }
  }

  updateParent(e) {
    this.dataEmitter.emit(e);
  }

  changed(e) {
    this.updateParent(["weberNote", this.weberNote]);
    this.updateParent(["rinneNote", this.rinneNote]);

    // this.updateParent([])
  }
}
