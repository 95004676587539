<table class="container-fluid">
  <dl class="row" style="font-size: larger">
    <div class="col-8">
      <span><strong>Patient Name : </strong> {{ patient.Name }}</span>
      <br />
      <span
        ><strong>Age/Sex : </strong> {{ patient.Age }} Years/{{ patient.Sex }}
      </span>
      <br />
    </div>
    <div class="col-4 text-right">
      <span
        ><strong>Date : </strong>
        <ng-container *ngIf="initData?.updatedAt">
          {{ initData?.updatedAt | date : "dd/MM/yyyy" }}
        </ng-container>
        <ng-container *ngIf="!initData?.updatedAt">
          {{ today | date : "dd/MM/yyyy" }}
        </ng-container>
      </span>
    </div>
    <div class="col-12">
      <strong>Complain : </strong> {{ patient.Complain }}
    </div>
  </dl>
</table>
