import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-chart-control",
  templateUrl: "./chart-control.component.html",
  styleUrls: ["./chart-control.component.css"],
})
export class ChartControlComponent implements OnInit {
  @Input() initData: any;
  constructor() {}

  ngOnInit(): void {
    //initially controls at parent is null

    if (this.initData != "none") {
      this.model.reliability = this.initData.reliability;
      this.model.validity = this.initData.validity;
      this.model.testTechnique = this.initData.testTechnique;
    }

    this.updateControl();
  }

  model: Model = new Model();

  @Output() controlUpdateEvent = new EventEmitter();

  updateControl() {
    this.controlUpdateEvent.emit(this.model);
  }
}

export class Model {
  pointerType: string = "ac";
  remove: boolean = false;
  noResponse: boolean = false;
  testTechnique: string = "Conventional";
  reliability: string = "Good";
  validity: string = "Acceptable";
}
