import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { strict } from "assert";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-add-audiologist",
  templateUrl: "./add-audiologist.component.html",
  styleUrls: ["./add-audiologist.component.css"],
})
export class AddAudiologistComponent implements OnInit {
  public formData: { [k: string]: any } = {};
  public id: string;
  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.apiService
          .get("/admin/audiologist/" + this.id)
          .subscribe((data) => {
            this.formData = data;
          });
      }
    });
  }

  submit() {
    if (!this.formData.password) {
      alert("password can't be empty");
    } else if (this.formData.password != this.formData.confirm_password) {
      alert("confirm password doesn't match");
    } else if (this.id) {
      this.apiService
        .patch("/admin/audiologistupdate/" + this.id, this.formData)
        .subscribe((data) => {
          alert("success");
        });
    } else if (!this.id) {
      this.apiService
        .post("/admin/audiologistregister", this.formData)
        .subscribe(
          (data) => {
            alert("success");
          },
          (err) => {
            alert(err.error.text);
          }
        );
    }
  }
}
